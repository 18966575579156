import { ReactNative } from '@/api/app/react-native';

import * as Twilio from '@/api/app/twilio';
import { AppNotification } from '@/api/app/app-notification';

interface SharedApi {
  getRememberToken: () => string;
  getSessionId: () => string;
  ReactNative: typeof ReactNative;
  Twilio: typeof Twilio;
  AppNotification: typeof AppNotification;
}

declare global {
  interface Window {
    sharedApi: SharedApi;
  }
}

const getRememberToken = () => document.getElementById('remember_token')?.innerText;
const getSessionId = () => document.getElementById('session_id')?.innerText;

window.sharedApi = {
  getRememberToken,
  getSessionId,
  ReactNative,
  Twilio,
  AppNotification,
};
